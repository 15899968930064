/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Seo from "./seo";
import Nav from "./navbar";

if (typeof window !== "undefined") {
    const UIkit = require("uikit/dist/js/uikit.min")
    const icons = require("uikit/dist/js/uikit-icons.min")
    UIkit.use(icons)
}

const Layout = ({ children, seo }) => (
    <StaticQuery
        query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              url
            }
          }
        }
      }
    `}
        render={(data) => (
            <>
                <Seo seo={seo} />
                <Nav />
                <main>{children}</main>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
